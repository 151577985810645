<!--
 * @Author: DY
 * @Date: 2020-05-27 14:52:13
 * @LastEditTime: 2020-09-14 14:50:42
 * @LastEditors: name
 * @Description: 企业大屏
 * @FilePath: \JT_Web_dev\src\views\enterpriseScreen\index_dy.vue
-->
<template>
    <div class="bigScreenBox">
        <div class="enterpriseScreen">
            <!-- <back></back> -->
            <header>
                <span @click="centerDialogVisible = true">东营中联混凝土大数据智能管控平台</span>
            </header>
            <section>
                <div class="content_l">
                    <div class="left_head">
                        <div class="selectTime">
                            <el-date-picker
                                v-model="salesVolumeValue"
                                type="month"
                                :editable="false"
                                placeholder="选择月"
                                value-format="yyyy-MM"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="left_t">
                        <div class="salesVolume">
                            <div class="total">
                                <div>
                                    <h4>本月销量(方):</h4>
                                    <p class="scrollNumbers scrollNumbers1" id="monthlySales"></p>
                                </div>
                                <div>
                                    <h4>年累计销量(万方):</h4>
                                    <p class="scrollNumbers scrollNumbers1" id="annualSales"></p>
                                </div>
                            </div>
                            <div class="chart" id="salesVolumeChart"></div>
                        </div>
                        <div class="price">
                            <div class="total">
                                <div>
                                    <h4>本月售价(元/方):</h4>
                                    <p class="scrollNumbers scrollNumbers1" id="monthlyPrice"></p>
                                </div>
                                <div>
                                    <h4>本年度均价(元/方):</h4>
                                    <p class="scrollNumbers scrollNumbers1" id="annualAveragePrice"></p>
                                </div>
                            </div>
                            <div class="chart" id="priceChart"></div>
                        </div>
                    </div>
                    <div class="left_b">
                        <div class="title">
                            <h4>各站量/均价对比</h4>
                        </div>
                        <div class="chart" id="contrast"></div>
                    </div>
                </div>
                <div class="content_c">
                    <div class="map">
                        <transportMap
                            :is-large-screen="true"
                            :page-type="'all'"
                            @siloSwitchingStation="siloSwitchingStation"
                        ></transportMap>
                    </div>
                    <div class="stock">
                        <div class="title">
                            <h4>库存情况</h4>
                        </div>
                        <div class="materialScience">
                            <el-button
                                size="small"
                                v-if="isScroll"
                                class="btn_l iconfont iconjiantouzuo"
                                @click="scrollLeft"
                            >
                            </el-button>
                            <div class="materialScienceBox">
                                <ul :style="{width: materialScienceWidth +'px'}">
                                    <template v-for="(item,index) in stock_fictitious">
                                        <li :key="index">
                                            <h4>{{ item.material_name }}</h4>
                                            <p class="orange" :class="{'green': item.type_dic_code == 204}">
                                                {{ item.volume_theory }}吨
                                            </p>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                            <el-button
                                size="small"
                                v-if="isScroll"
                                class="btn_r iconfont iconjiantouyou"
                                @click="scrollRight"
                            >
                            </el-button>
                        </div>
                        <!-- <div class="charts" id="aaa"></div> -->
                        <div class="chartBox">
                            <div class="productionLineCharts productionLine_l">
                                <div class="chartHead">
                                    <el-select
                                        v-model="productionLine_l"
                                        placeholder="请选择"
                                        @change="switchProductionLine('productionLine_l')"
                                    >
                                        <el-option
                                            v-for="item in productionLine"
                                            :key="item.line_no"
                                            :label="item.line_name"
                                            :value="item.line_no"
                                            :disabled="item.disabled"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="charts">
                                    <div class="coneColumn"></div>
                                    <div class="columnChart"></div>
                                </div>
                            </div>
                            <div class="productionLineCharts productionLine_r">
                                <div class="chartHead">
                                    <el-select
                                        v-model="productionLine_r"
                                        placeholder="请选择"
                                        @change="switchProductionLine('productionLine_r')"
                                    >
                                        <el-option
                                            v-for="item in productionLine"
                                            :key="item.line_no"
                                            :label="item.line_name"
                                            :value="item.line_no"
                                            :disabled="item.disabled"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="charts">
                                    <div class="coneColumn"></div>
                                    <div class="columnChart"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content_r">
                    <div class="dailyData">
                        <div class="title">
                            <h4>每日数据</h4>
                        </div>
                        <div class="header">
                            <div class="el_left time iconfont iconbelow-s">
                                <el-date-picker
                                    v-model="dailyDataTime"
                                    type="date"
                                    placeholder="选择日期"
                                    :editable="false"
                                    value-format="yyyy-MM-dd"
                                    @change="switchingDate"
                                >
                                </el-date-picker>
                            </div>
                            <div class="el_right type">
                                <div class="chartHead">
                                    <el-select
                                        v-model="defaultStation"
                                        placeholder="请选择"
                                        @change="switchSites"
                                    >
                                        <el-option
                                            v-for="item in stationData"
                                            :key="item.station_id"
                                            :label="item.name"
                                            :value="item.station_id"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="total">
                            <div>
                                <h4>总任务：</h4>
                                <p class="scrollNumbers scrollNumbers2" id="generalTask"></p>
                            </div>
                            <div>
                                <h4>任务方量：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="task"></p>
                            </div>
                            <div>
                                <h4>销售方量：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="salesVolume"></p>
                            </div>
                            <div>
                                <h4>完成率(%)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="completionRate"></p>
                            </div>
                            <div class="salesDetails">
                                <i class="iconfont iconshangjiantou"></i>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h4>{{ totalCount.fhfl }}</h4>
                                                <p>发货方量</p>
                                            </td>
                                            <td>
                                                <h4>{{ totalCount.ddpdl }}</h4>
                                                <p>调度派单量</p>
                                            </td>
                                            <td>
                                                <h4>{{ totalCount.scfl }}</h4>
                                                <p>生产方量</p>
                                            </td>
                                            <td>
                                                <h4>{{ totalCount.ccgbl }}</h4>
                                                <p>出厂过磅量</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h4>{{ totalCount.sjqsfl }}</h4>
                                                <p>司机签收方量</p>
                                            </td>
                                            <td>
                                                <h4>{{ totalCount.khqsfl }}</h4>
                                                <p>客户签收方量</p>
                                            </td>
                                            <td>
                                                <h4>{{ totalCount.ttfl }}</h4>
                                                <p>退砼方量</p>
                                            </td>
                                            <td>
                                                <h4>{{ totalCount.pyfl }}</h4>
                                                <p>盘盈方量</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="productionSituation">
                            <h5>生产情况</h5>
                            <div class="chart" id="productionSituationChart"></div>
                        </div>
                    </div>
                    <div class="controlNotice">
                        <div class="title">
                            <h4>管控通知</h4>
                        </div>
                        <p class="noData" v-if="controlNoticeData.length<1">
                            暂无数据
                        </p>
                        <ul v-else>
                            <template v-for="(item,index) in controlNoticeData">
                                <li
                                    :key="index"
                                    :class="{'earlyWarning': item.group == '301','control': item.group == '201'}"
                                    :title="item.title"
                                    v-if="item.group == '201' || item.group == '301'"
                                >
                                    <h5 v-if="item.group == '301'">
                                        预警通知
                                    </h5>
                                    <h5 v-if="item.group == '201'">
                                        管控通知
                                    </h5>
                                    <p>
                                        {{ item.title }}
                                    </p>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import './css/enterpriseScreen_dy1.0.styl';
import {
    drawPictorialBar,
    drawLine,
    lineColumn,
    stereogramBar,
    drawBar,
} from './../../assets/js/charts';
import { DigitRoll } from './../../assets/js/charts/digitRoll.js';

// 地图组件
import transportMap from './../productionManagement/logisticsMonitoring/vehicleLogisticsMonitoring';

export default {
    // 领导驾驶舱
    name: 'leading-cockpit',
    components: {
        transportMap,
    },
    props: {},
    data() {
        return {
            // 销量时间
            salesVolumeValue: '2020-08',
            // 库存情况-虚拟料仓（砂、石）
            stock_fictitious: [],
            // 库存情况-虚拟料仓宽度
            materialScienceWidth: '',
            // 库存情况-虚拟料仓容器宽度
            materialScienceBoxWidth: '',
            // 库存情况-虚拟料仓是否可滚动
            isScroll: false,
            // 库存情况-物理料仓
            stock_physics: [],
            // 站点数据
            productionLine: [],
            // 已选站点1
            productionLine_l: '',
            // 已选站点2
            productionLine_r: '',
            // 可选场站
            optionalProductionLine: [],
            // 每日数据时间
            dailyDataTime: '2020-06-04',
            // 每日数据默认场站
            defaultStation: '',
            // 每日数据场站数据
            stationData: [{
                name: '全部',
                station_id: '',
            }],
            // 每日数据任务数据
            totalCount: {
                ccgbl: 0,
                ddpdl: 0,
                fhfl: 0,
                khqsfl: 0,
                pyfl: 0,
                rwfl: 0,
                rws: 0,
                scfl: 0,
                sjqsfl: 0,
                ttfl: 0,
                wcl: 0,
                xsfl: 0,
                yfcs: 0,
            },
            // 管控通知数据
            controlNoticeData: [],
        };
    },
    computed: {},
    watch: {
        salesVolumeValue() {
            // 初始化量
            this.initSalesVolume();
            // 初始化售价
            this.initPrice();
            // 获取销量和均价对比数据
            this.getContrast();
        },
    },
    created() {
        // 销量时间
        this.salesVolumeValue = this.getNowFormatDate('month');
        // 每日数据时间
        this.dailyDataTime = this.getNowFormatDate('date');
    },
    mounted() {
        // 处理signlar消息
        this.handleSignlarMsg();
        // 月销量
        this.electronicNumber('#monthlySales', 0);
        // 年销量
        this.electronicNumber('#annualSales', 0);
        // 月售价
        this.electronicNumber('#monthlyPrice', 0);
        // 年均价
        this.electronicNumber('#annualAveragePrice', 0);

        // 初始化量
        this.initSalesVolume();

        // 初始化售价
        this.initPrice();

        // 获取销量和均价对比数据
        this.getContrast();

        // 获取站点数据
        this.getStationData();

        // 总任务
        this.electronicNumber('#generalTask', 0);
        // 任务方量
        this.electronicNumber('#task', 0);
        // 销售方量
        this.electronicNumber('#salesVolume', 0);
        // 完成率
        this.electronicNumber('#completionRate', 0);

        // 获取每日数据
        this.getDayCount();
        // 每日数据-生产情况
        this.getGradeChart();

        // 拉取管控通知数据
        this.getControlNoticeData();
    },
    methods: {
        // 格式化电子数字
        electronicNumber(obj, nub) {
            if (!nub) {
                nub = 0.0;
            }
            new DigitRoll({
                container: obj,
            }).roll(nub);
        },
        // 获取当前日期
        getNowFormatDate(type) {
            const date = new Date();
            const seperator1 = '-';
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            let currentdate = '';
            if (type === 'year') {
                currentdate = year;
            } else if (type === 'month') {
                currentdate = year + seperator1 + month;
            } else if (type === 'date') {
                currentdate = year + seperator1 + month + seperator1 + strDate;
            }
            return currentdate;
        },
        // 初始化量
        initSalesVolume() {
            const requestTime = {
                Year: this.salesVolumeValue.split('-')[0],
                Month: this.salesVolumeValue.split('-')[1],
            };
            const nubUrl = 'largescreen/qty/tj';
            this.getTotal(requestTime, nubUrl, 'salesVolume');

            const chartUrl = 'largescreen/qty';
            this.getChartData(requestTime, chartUrl, 'salesVolume');
        },
        // 初始化售价
        initPrice() {
            const requestTime = {
                Year: this.salesVolumeValue.split('-')[0],
                Month: this.salesVolumeValue.split('-')[1],
            };
            const nubUrl = 'largescreen/xsjg/tj';
            this.getTotal(requestTime, nubUrl, 'price');

            const chartUrl = 'largescreen/xsjg';
            this.getChartData(requestTime, chartUrl, 'price');
        },
        // 获取价/量头部数据
        getTotal(requestTime, nubUrl, type) {
            this.$axios
                .get(`/interfaceApi/report/${nubUrl}/${requestTime.Year}/${requestTime.Month}/0`)
                .then(res => {
                    if (type === 'salesVolume') {
                        // 月销量
                        this.electronicNumber('#monthlySales', res.byxl);
                        // 年销量
                        const newBnxl = res.bnxl / 10000;
                        this.electronicNumber('#annualSales', newBnxl);
                    } else if (type === 'price') {
                        // 月售价
                        this.electronicNumber('#monthlyPrice', res.bysj);
                        // 年均价
                        this.electronicNumber('#annualAveragePrice', res.bnjj);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取价/量图表数据
        getChartData(requestTime, chartUrl, type) {
            this.$axios
                .get(`/interfaceApi/report/${chartUrl}/${requestTime.Year}/${requestTime.Month}/0?datenum=6`)
                .then(res => {
                    if (type === 'salesVolume') {
                        const chartData = {
                            xdata: [],
                            yAxisName: ['(方)'],
                            ydata: [
                                {
                                    data: [],
                                },
                            ],
                        };
                        res.forEach(item => {
                            chartData.xdata.push(item.xsy + '月');
                            chartData.ydata[0].data.push(item.xsl);
                        });
                        drawPictorialBar('salesVolumeChart', chartData);
                    } else if (type === 'price') {
                        const chartData = {
                            xdata: [],
                            legendData: ['年平均价'],
                            ydata: [
                                {
                                    name: '年平均价',
                                    color: ['rgba(250, 132, 54, 1)', 'rgba(255, 145, 84, 0.8)', 'rgba(255, 145, 84, 0.1)'],
                                    data: [],
                                },
                            ],
                        };
                        res.forEach(item => {
                            chartData.xdata.push(item.xsy + '月');
                            chartData.ydata[0].data.push(item.xsdj);
                        });
                        drawLine('priceChart', chartData);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取销量和均价对比数据
        getContrast() {
            this.$axios
                .get(`/interfaceApi/report//salesanalysis/xsqk/xljj/bn?datenum=6&end_time=${this.salesVolumeValue}`)
                .then(res => {
                    const chartData = {
                        xAxis: {
                            data: [],
                        },
                        yAxis: [
                            {
                                name: '(方)',
                                nameTextStyle: [0, 0, -7, 0],
                            }, {
                                name: '(元/方)',
                                nameTextStyle: [0, 0, -7, 0],
                            },
                        ],
                        series: [],
                    };
                    chartData.xAxis.data = res.date;
                    const barData = [];
                    const lineData = [];
                    const newData = [];
                    res.date.forEach(() => {
                        newData.push(0);
                    });
                    const colors = [{
                        barColor: ['#1FE3FF', '#2F7FF1'],
                        lineColor: ['#9759D5'],
                    }, {
                        barColor: ['#FFC854', '#F96935'],
                        lineColor: ['#8D2327'],
                    }, {
                        barColor: ['#A7D35F', '#6DBF47'],
                        lineColor: ['#F77F3D'],
                    }, {
                        barColor: ['#FCEF1F', '#FDBD29'],
                        lineColor: ['#87D563'],
                    }, {
                        barColor: ['#AB75E7', '#4131A3'],
                        lineColor: ['#013D83'],
                    }, {
                        barColor: ['#67C7FD', '#1F65D5'],
                        lineColor: ['#E6C336'],
                    }, {
                        barColor: ['#FB9163', '#E34541'],
                        lineColor: ['#FF0000'],
                    }];
                    res.name.forEach((item, index) => {
                        barData.push({
                            type: 'bar',
                            name: item,
                            maxbarWidth: 32,
                            color: colors[index].barColor,
                            data: JSON.parse(JSON.stringify(newData)),
                        });
                        lineData.push({
                            type: 'line',
                            name: item + ' ',
                            color: colors[index].lineColor,
                            data: JSON.parse(JSON.stringify(newData)),
                        });
                    });
                    chartData.series = [...barData, ...lineData];


                    res.list.forEach(k => {
                        let newIndex = 0;
                        res.date.forEach((item, index) => {
                            if (item === k.month) {
                                newIndex = index;
                            }
                        });
                        chartData.series.forEach(z => {
                            if (z.name.indexOf(k.station_name) !== -1) {
                                if (z.type === 'bar') {
                                    z.data[newIndex] = k.zfl;
                                } else if (z.type === 'line') {
                                    z.data[newIndex] = k.ypjdj;
                                }
                            }
                        });
                    });
                    lineColumn('contrast', chartData);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取站点数据
        getStationData() {
            const CompanyId = this.$takeTokenParameters('CompanyId');
            const BranchId = this.$takeTokenParameters('BranchId');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_list?companyId=' + CompanyId + '&branchId=' + BranchId)
                .then(res => {
                    if (res) {
                        this.stationData.push(...res);

                        // 获取库存情况-虚拟料仓（砂、石）
                        this.getStock_fictitious(res[0].station_id);
                        // 获取库存情况-物理料仓
                        this.getStock_physics(res[0].station_id);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取库存情况-虚拟料仓（砂、石）
        getStock_fictitious(defaultStation) {
            this.$axios
                .get(`/interfaceApi/report/largescreen3/kcqk/xnlc/${defaultStation}`)
                .then(res => {
                    if (res) {
                        this.stock_fictitious = res;
                        this.materialScienceWidth = res.length * 130 - 20;
                        this.materialScienceBoxWidth = this.$('.materialScienceBox').width();
                        if (this.materialScienceWidth > this.materialScienceBoxWidth) {
                            this.isScroll = true;
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 虚拟料仓左移动
        scrollLeft() {
            if (this.isScroll) {
                this.$('.materialScienceBox ul').css('left', 0);
            }
        },
        // 虚拟料仓右移动
        scrollRight() {
            if (this.isScroll) {
                const newLeft = this.materialScienceWidth - this.materialScienceBoxWidth;
                this.$('.materialScienceBox ul').css('left', -newLeft);
            }
        },
        // 获取库存情况-物理料仓
        async getStock_physics(defaultStation) {
            await this.$axios
                .get(`/interfaceApi/report/largescreen3/kcqk/wllc/${defaultStation}`)
                .then(res => {
                    if (res) {
                        this.getStock_physicsHandl(res, defaultStation);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取库存情况-物理料仓数据处理
        getStock_physicsHandl(data, defaultStation) {
            this.stock_physics = [];
            let flag = 0;
            let wdy = {
                title: '',
                sur_name: '',
            };
            for (let i = 0; i < data.length; i++) {
                let az = '';
                for (let j = 0; j < this.stock_physics.length; j++) {
                    if (this.stock_physics[j].title === data[i].team_id) {
                        flag = 1;
                        az = j;
                        break;
                    }
                }
                if (flag === 1) {
                    const ab = this.stock_physics[az];
                    ab.sur_name.push(data[i]);
                    flag = 0;

                } else if (flag === 0) {
                    wdy = {};
                    wdy.title = data[i].team_id;
                    wdy.sur_name = new Array();
                    wdy.sur_name.push(data[i]);
                    this.stock_physics.push(wdy);
                }
            }
            // 获取库存情况-物理料仓生产线数据
            this.getProductionLine(defaultStation);
        },
        // 获取库存情况-物理料仓生产线数据
        async getProductionLine(defaultStation) {
            await this.$axios
                .get(`/interfaceApi/report/largescreen3/scx/${defaultStation}`)
                .then(res => {
                    if (res) {
                        this.productionLine = res;
                        this.productionLine_l = res[0].line_no;
                        this.productionLine_l_chart(this.productionLine_l);
                        this.productionLine_r = res[1].line_no;
                        this.productionLine_r_chart(this.productionLine_r);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换物理料仓生产线数据
        switchProductionLine(obj) {
            if (obj === 'productionLine_l') {
                this.productionLine_l_chart(this.productionLine_l);
            } else if (obj === 'productionLine_r') {
                this.productionLine_r_chart(this.productionLine_r);
            }
        },
        // 加载左边柱图
        productionLine_l_chart(newVal) {
            this.$('.productionLine_l .coneColumn').html('');
            this.$('.productionLine_l .columnChart').html('');
            this.stock_physics.forEach(item => {
                if (item.title === newVal) {
                    item.sur_name.forEach((k, i) => {
                        const newDom = `<div class="chart" id="materielChar_l${i}"></div>`;
                        if (k.type_dic_code !== '208') {
                            this.$('.productionLine_l .coneColumn').append(newDom);
                        } else {
                            this.$('.productionLine_l .columnChart').append(newDom);
                        }
                        stereogramBar('materielChar_l' + i + '', k);
                    });
                }
            });
            this.$forceUpdate();
        },
        // 加载右边柱图
        productionLine_r_chart(newVal) {
            this.$('.productionLine_r .coneColumn').html('');
            this.$('.productionLine_r .columnChart').html('');
            this.stock_physics.forEach(item => {
                if (item.title === newVal) {
                    item.sur_name.forEach((k, i) => {
                        const newDom = `<div class="chart" id="materielChar_r${i}"></div>`;
                        if (k.type_dic_code !== '208') {
                            this.$('.productionLine_r .coneColumn').append(newDom);
                        } else {
                            this.$('.productionLine_r .columnChart').append(newDom);
                        }
                        stereogramBar('materielChar_r' + i + '', k);
                    });
                }
            });
            this.$forceUpdate();
        },
        // 料仓切换场站
        siloSwitchingStation(defaultStation) {
            // 获取库存情况-虚拟料仓（砂、石）
            this.getStock_fictitious(defaultStation);
            // 获取库存情况-物理料仓
            this.getStock_physics(defaultStation);
        },
        // 每日数据-总任务、任务方量、生产方量、完成率
        getDayCount() {
            this.$axios
                .get(`/interfaceApi/report/tjfx/mrsj/tjs?station_id=${this.defaultStation}&start_time=${this.dailyDataTime}`)
                .then(res => {
                    this.totalCount = res;
                    // 总任务
                    this.electronicNumber('#generalTask', this.totalCount.rws);
                    // 任务方量
                    this.electronicNumber('#task', this.totalCount.rwfl);
                    // 销售方量
                    this.electronicNumber('#salesVolume', this.totalCount.xsfl);
                    // 完成率
                    this.electronicNumber('#completionRate', this.totalCount.wcl);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 每日数据-生产情况
        getGradeChart() {
            this.$axios
                .get(`/interfaceApi/report/tjfx/mrsj/scfldb/abh?station_id=${this.defaultStation}&start_time=${this.dailyDataTime}`)
                .then(res => {
                    const chartData = {
                        xAxis: {
                            data: [],
                        },
                        yAxis: {
                            name: '(方)',
                            nameTextStyle: [0, 0, -7, 0],
                        },
                        series: [
                            {
                                name: '任务方量',
                                barWidth: 24,
                                color: [ '#20E5FF', '#2F7EF2'],
                                data: [],
                            }, {
                                name: '生产方量',
                                barWidth: 24,
                                color: ['#FFC452', '#FB7D3C' ],
                                data: [],
                            },
                        ],
                    };
                    res.forEach(element => {
                        chartData.xAxis.data.push(element.name);
                        chartData.series[0].data.push(element.rwfl);
                        chartData.series[1].data.push(element.scfl);
                    });
                    drawBar('productionSituationChart', chartData);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 每日数据切换日期
        switchingDate() {
            // 获取每日数据
            this.getDayCount();
            // 每日数据-生产情况
            this.getGradeChart();
        },
        // 每日数据切换场站
        switchSites() {
            // 获取每日数据
            this.getDayCount();
            // 每日数据-生产情况
            this.getGradeChart();
        },
        // 拉取管控通知数据
        getControlNoticeData() {
            this.$axios
                .get('/interfaceApi/message/MessageInfo/get_notice_new/6/201,301')
                .then(res => {
                    this.controlNoticeData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 消息更新
        messageUpdate(data, type) {
            const _that = this;
            const thisType = type;
            const tenant_id = this.$takeTokenParameters('TenantId');
            if (
                data.tenant_id === tenant_id
                && data.station_id === this.defaultStation
                && this.dailyDataTime === this.currentDate
                && this.isRefresh === false
            ) {
                _that.isRefresh = true;
                setTimeout(() => {
                    if (_that.isRefresh === true) {
                        _that.isRefresh = false;
                        if (thisType === 1) {
                            // 获取每日数据
                            _that.getDayCount();
                            // 每日数据-生产情况
                            _that.getGradeChart();
                        } else if (thisType === 2) {
                            // 获取库存情况-虚拟料仓（砂、石）
                            this.getStock_fictitious();
                            // 获取库存情况-物理料仓
                            this.getStock_physics();
                            // 获取每日数据
                            this.getDayCount();
                            // 每日数据-生产情况
                            this.getGradeChart();
                        } else if (thisType === 3) {
                            // 拉取管控通知数据
                            this.getControlNoticeData();
                        }
                    }
                }, this.refreshTime);
            }
        },

        // 处理signlar消息
        handleSignlarMsg() {
            /* 连接signlar */
            const _that = this;
            const token = sessionStorage.getItem('token').split(' ')[1];
            const signalR = require('../../../public/signalr/signalr.min');
            const connection = new signalR.HubConnectionBuilder()
                .withUrl('/interfaceApi/signalr/signalRHubService', {
                    accessTokenFactory: () => token,
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                }).build();
            // 生产任务下发消息
            connection.on('TaskDistributionSignalRMessage', function (data) {
                _that.messageUpdate(data, 1);
            });
            // 派车单完成消息
            connection.on('ProductPcbCompleteSignalRMessage', function (data) {
                _that.messageUpdate(data, 2);
            });
            // 管控通知消息
            connection.on('MessageNeteaseNoticeDto', function (data) {
                _that.messageUpdate(data, 3);
            });
            connection.start().then(function () {
                console.log('连接signlar成功！');
            }).catch(function (err) {
                return console.error(err.toString());
            });
        },
    },
};
</script>
<style lang="stylus" scoped></style>
